<script lang="ts" setup>
const $img = useImage();

const imageFiles = {
  'Facetune_11-01-2024-15-51-43.jpeg': "A Staffordshire Bull Terrier wearing a lifejacket in a dog hydrotherapy pool with a hydrotherapist.",
  'IMG_4863.jpeg': "A Cocker Spaniel sitting on the edge of a dog hydrotherapy pool. ",
  'IMG_5138.jpeg': "A Cocker Spaniel in a lifejacket, swimming in a dog hydrotherapy pool.",
  'IMG_5722.jpeg': "A Springer Spaniel wearing a dog drying robe, sitting on the edge of a dog hydrotherapy pool. ",
  'IMG_6058.jpeg': "A Springer Spaniel wearing a dog drying robe, sitting in a showering area.",
  'IMG_6248.jpeg': "A Cocker Spaniel in a harness, being showered by a dog hydrotherapist.",
  'IMG_6500.jpeg': "A Jack Russell Terrier in a harness in a dog hydrotherapy pool.",
  'Teddie in bike trailer.jpeg': "A Staffordshire Bull Terrier wrapped up in a purple towel in the back of a push bike trailer.",
  'Rascal in reception.jpeg': "A Terrier Cross in a dog drying robe, sitting in the reception of a dog hydrotherapy centre.",
  'Pippa in lifejacket vs harness.jpeg': "One Staffordshire Bull Terrier in a lifejacket and another in a harness standing in a dog hydrotherapy pool.",
  'Lilly in ear wrap.jpeg':"A Labrador Retriever in a harness and earwrap, standing in a dog hydrotherapy pool, looking up at the camera.",
  'Charlie in drying robe.jpeg': "A Cocker Spaniel in a blue BooDoggo drying robe in the reception of a dog hydrotherapy centre.",
};

</script>

<template>
  <Carousel :autoplay=5000 :pause-autoplay-on-hover="true" :wrap-around="true" class="w-full">
    <Slide v-for="(value, name, index) in imageFiles" :key="index">
      <NuxtPicture
          :img-attrs="{class: 'w-full h-full object-contain object-center', 'data-proofer-ignore': ''}"
          :alt="value"
          class="h-full"
          sizes="100vw md:800px"
          :src="`dogs/rosecroft/${name}`"
      />
    </Slide>

    <template #addons>
      <Navigation/>
      <Pagination/>
    </template>
  </Carousel>
</template>

<style lang="postcss" scoped>
.carousel__slide {
  @apply h-[600px] max-h-screen;
}

</style>
