<script setup lang="ts">
import RosecroftDogCarousel from "~/components/RosecroftDogCarousel.vue";

const app = useAppConfig();

useSeoMeta({
  description: `${app.title} is a canine hydrotherapy centre located in Stafford, UK. `
})
</script>

<template>
  <div class="bg-gradient-to-b from-rosecroft-gray to-rosecroft-alt from-25%">
    <div class="container px-10 md:px-0 mx-auto justify-center">
      <div id="hero" class="flex items-end justify-between ml-auto mr-auto w-full sm:w-3/4">
        <div>
          <NuxtPicture
              :img-attrs="{class: 'max-h-80 md:max-h-96', height: 400, width: 300}"
              class="max-h-72 md:max-h-80"
              src="dogs/dog-waving-paw.png" alt="A dog waving it's paw"/>
        </div>
        <div class="flex-initial max-h-full">
          <div class="flex flex-col text-center max-h-full">
            <h1>
              <NuxtPicture
                  id="main-logo"
                  :img-attrs="{class:'max-h-72 md:max-h-80 mx-auto', height: 320, width: 338}"
                  src="logos/rosecroft-logo-color.png"
                  alt="Rosecroft Rehab - Canine Rehabilitation Centre"
              />
            </h1>
            <h2 class="pb-1 md:pb-5 text-2xl md:text-4xl lg:text-5xl font-bold text-white text-shadow shadow-rosecroft-gray">
              Welcomes you with open paws</h2>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="hydro-benefits" class="jarallax" data-jarallax>
    <NuxtPicture src="dogs/dog-swimming.jpg" class="jarallax-img"
                 alt="A black dog wearing a red harness splashing in water whilst looking just away from the camera"/>
    <div
        class="container object-scale-down relative mx-auto flex flex-col h-full min-h-fit gap-20 py-20 justify-evenly">
      <h2 class="text-3xl md:text-5xl font-bold text-center p-8">Canine Hydrotherapy Benefits</h2>
      <ul class="flex flex-wrap justify-between gap-10 content-between text-center">
        <li>Post-Operative Rehabilitation</li>
        <li>Increased Cardiovascular Function</li>
        <li>Management of Degenerative Conditions</li>
        <li>Relief from Pain, Swelling & Stiffness</li>
        <li>Joint Mobilisation & Increased ROM</li>
        <li>Restoring & Increasing Muscle Mass</li>
        <li>Increased Proprioception & Mental Well-Being</li>
      </ul>
    </div>
  </div>

  <section>
    <h2 class="text-center py-10">The Dogs of Rosecroft</h2>
    <RosecroftDogCarousel/>
  </section>

  <div id="why-rosecroft" class="jarallax" data-jarallax>
    <NuxtPicture src="dogs/blue-water.jpg" class="jarallax-img" alt="A landscape of a calm blue sea on a foggy day"/>
    <div class="container object-scale-down mx-auto min-h-screen flex flex-col gap-20 py-20 justify-evenly">
      <h2 class="text-3xl md:text-5xl font-bold text-center p-8">Why Rosecroft?</h2>
      <ul class="flex flex-wrap gap-10 justify-between content-between text-center">
        <li>Brand New, Custom-Built, 5 x 3 Metre Heated Hydrotherapy Pool</li>
        <li>Level 3 Qualified Canine Hydrotherapist</li>
        <li>Skinner's Dog Food, Bugalugs Shampoo &amp; More</li>
        <li>Private On-Site Parking & Disabled Access</li>
        <li>IRVAP Registered</li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="postcss">
#main-logo {
  @apply shadow-empress-500;
  filter: drop-shadow(0.0rem 0.0rem 0.02rem var(--tw-shadow-color));
}

#hydro-benefits, #why-rosecroft {
  h2, li {
    @apply bg-rosecroft-light bg-opacity-50 border-4 border-gray-400 rounded-xl shadow-2xl;
  }

  li {
    @apply basis-5/12 text-2xl p-4 items-center justify-center flex flex-row flex-grow;
    text-shadow: 0px 0px 1rem theme('colors.rosecroft.light');
  }
}
</style>
